<template>
  <div >
    <nut-navbar
      :left-show="false"
      :title="$t('HELP.H1')"
      fixed=true
      @on-click-icon="iconClick"
      titIcon="ask2"
      class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50 scroll-body">
      <div class="p20" style="padding:10px 5px !important">

          <div class="type-title">
            <span :class="listType === '' ? 'a' : ''" @click="typeClick()">All</span>
            <span :class="listType === 'wait_reply' ? 'a' : ''" @click="typeClick('wait_reply')">{{$t('HELP.WAITREPLY')}}</span>
            <span :class="listType === 'replied' ? 'a' : ''" @click="typeClick('replied')">{{$t('HELP.REPLIED')}}</span>
            <span :class="listType === 'closed' ? 'a' : ''" @click="typeClick('closed')">{{$t('HELP.CLOSED')}}</span>
          </div>

          <ListEmpty v-show="listNoData"></ListEmpty>
          
          <div class="csls" id="scroll" style="margin-top:10px;" >
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore">

              <div v-for="(item, index) in list" :key="index" :class="'csls-item ' + 't_' + item.status"  @click="toTicketDetail(item.id)">
                <h4>
                  <span v-if="item.type.includes('recharge')">Recharge</span>
                  <span v-if="item.type.includes('withdraw')">Withdraw</span>
                  <span v-if="item.type.includes('business')">Promotion</span>
                  <span v-if="item.type.includes('feedback')">Others</span>
                  <span class="r">{{item.createTime}}</span>
                </h4>
                <p>
                </p>
                <div class="bd">
                  {{item.content}}
                </div>
                <div class="imgs" v-if="item.imgs.length > 0">
                  <img v-for="(itemImg, index) in item.imgs" :key="index" :src="itemImg" @click="checkImg($event,itemImg)"/>
                </div>
                <div v-if="item.status === '1' || item.status === '2' " class="ricon1">Wait reply</div>
                <div v-if="item.status === '3'" class="ricon3">Replied</div>
                <div v-if="item.status === '4'" class="ricon4">Closed</div>
              </div>

            </nut-infiniteloading>
          </div>

      </div>
    </div>
    


    <!--**************************************** 图片预览 ****************************************-->
    <nut-popup :style="{ width: '90%' }"  v-model:visible="showPreview">
      <div class="preview-pop" @click="showPreview = false">
        <img :src="uploadImgBase64">
      </div>
    </nut-popup>

  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      SystemTicket2StatusEnum:{
        userReply: "user_reply",
        adminReply: "admin_reply",
        closed: "closed"
      },
      SystemTicket2TypeEnum:{
        recharge:'recharge',
        withdraw:'withdraw',
        invite:'invite',
        others:'others',
      },
      listType: '',
      list: [],
      listNoData: true,
      current: 1,
      hasNext:'',
      systemNotification: false,
      showPreview: false,  //图片预览
      uploadImgBase64:'',
    };

    

  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    typeClick(t){
      if(t === 'wait_reply'){
        this.listType = "wait_reply"
      }else if(t === 'replied'){
        this.listType = "replied"
      }else if(t === 'closed'){
        this.listType = "closed"
      }else{
        this.listType = ""
      }
      this.list = []
      this.getList(1);
    },

    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
    checkImg(e,url){
      this.uploadImgBase64 = url;
      this.showPreview = true;
      e.stopPropagation();
      e.preventDefault()
    },
    getList(c) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/system/ticket2/paginate",
        method: "get",
        params: { current: c,  status: this.listType},
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
            this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }else{
          this.listNoData = true;
        }
        Toast.hide("loading")
      });
    },
    toTicketDetail(id){
        this.$router.push({
            path: 'ticket2Detail',
            query: {
              id: id,
            }
          })
      }

  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.getList(1);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.push({
          name: "myHome",
        });
      },
      iconClick() {
        router.push({
          name: "helpSupport",
        });
      },

    };
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped>

.csls-item{ border: 1px solid #000;margin: 10px;border-radius: 10px; position: relative;
   overflow: hidden;}

.csls-item.t3{ border: 1px solid #4d86a1;margin-top: 10px;border-radius: 20px 20px 30px 0; overflow: hidden;
  margin-left: 0px; margin-right: 50px; }
.csls-item.t3 h4{ background: #d3eaf7 url(../assets/images/global_icon/202405-22.png) 5px 10px no-repeat; background-size: 20px;
padding-left: 30px;}
.csls-item h4{ margin: 0; height: 40px; line-height: 40px; padding: 0 10px; 
  background:  url(../assets/images/game_wheel/bg183.png) 5px 10px no-repeat; background-size: 20px;
padding-left: 30px;}
.csls-item h4 .r{ float: right; font-size: 14px; font-weight: 300; opacity: .8;}
.csls-item .bd{ padding: 10px; line-height: 20px; margin-bottom: 10px;}
.csls-item .imgs{ border-top: 1px dashed #2e405d; padding: 10px;}
.csls-item .imgs img{width: 33%; height: auto; max-width: 200px; max-height: 200px;}

.ricon1, .ricon3, .ricon4{ position: absolute; right: 0; bottom: 0;  text-align: center; background: #ccc; color: #fff;
  height: 24px;line-height: 24px;width: 70px; border-radius: 10px 0 0 0;}
  .ricon1{  background: #c2a500; color: #fff;width: 90px; }
  .ricon3{  background: #48a200; color: #fff;}

</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>